'use client';

import {
	MediaPlayer,
	MediaPlayerInstance,
	MediaProvider,
	Poster,
	type MediaTimeUpdateEvent,
	type MediaTimeUpdateEventDetail,
} from '@vidstack/react';
import '@vidstack/react/player/styles/base.css';
import dynamic from 'next/dynamic';
import { type ComponentProps, forwardRef, useRef } from 'react';
import VideoBackground, { type VideoBackgroundProps } from './VideoBackground';
import { useDebounceCallback } from 'usehooks-ts';

const VideoControlsFull = dynamic(() => import('./VideoControlsFull'), { ssr: false });
const VideoControlsBasic = dynamic(() => import('./VideoControlsBasic'), { ssr: false });

type VideoPlayerProps = Omit<ComponentProps<typeof MediaPlayer>, 'children'> & {
	controlsType?: 'basic' | 'full' | 'no-controls';
} & Partial<VideoBackgroundProps>;

const VideoPlayer = forwardRef<MediaPlayerInstance, VideoPlayerProps>(
	({ src, buttonColor, buttonPosition, controlsType = 'full', ...rest }, ref) => {
		const playerRef = useRef<MediaPlayerInstance | null>(null);

		const onTimeUpdate = (detail: MediaTimeUpdateEventDetail, nativeEvent: MediaTimeUpdateEvent) => {
			if (!rest.loop) return;
			if (!playerRef.current?.duration) return;

			if (detail.currentTime >= playerRef.current?.duration - 0.05) {
				playerRef.current.currentTime = 0;
				playerRef.current.play();
			}
		};

		const debouncedOnTimeUpdate = useDebounceCallback(onTimeUpdate, 500);
		let controlsMarkup = undefined;

		if (controlsType !== 'no-controls') {
			controlsMarkup = controlsType === 'full' ? <VideoControlsFull /> : <VideoControlsBasic />;
		}

		return (
			<MediaPlayer
				className="relative aspect-video w-full overflow-hidden rounded-lg bg-slate-900 font-sans text-white ring-media-focus data-[hover]:cursor-pointer data-[focus]:ring-4"
				crossOrigin
				playsInline
				ref={(instance) => {
					playerRef.current = instance;

					if (typeof ref === 'function') {
						ref(instance);
					} else if (typeof ref === 'object') {
						playerRef.current = instance;
					}
				}}
				onTimeUpdate={debouncedOnTimeUpdate}
				src={typeof src === 'string' ? { src: src + '&loop=1', type: 'video/vimeo' } : src}
				load={rest.poster ? 'play' : 'visible'}
				posterLoad={rest.poster ? 'idle' : 'visible'}
				{...rest}>
				<MediaProvider>{rest.poster && <Poster src={rest.poster} />}</MediaProvider>
				{rest.poster && <VideoBackground buttonColor={buttonColor} buttonPosition={buttonPosition} />}
				{controlsMarkup}
			</MediaPlayer>
		);
	}
);
VideoPlayer.displayName = 'VideoPlayer';

export default VideoPlayer;
